import React from "react";
import LetterHeader from "../components/LetterHeader";
import vector from "../images/Logos/Vector.svg";
import banner from "../images/Logos/banner.svg";
import LetterFooter from "../components/LetterFooter";

function OrgCreate() {
  return (
    <div className="org-create-page">
      <div className="letter-page">
        <LetterHeader/>
    <div className="container">
    <div className="letter-para">
     <h2>Org created<br/> Successfully!</h2>
      <p className="para-align">
        Your journey to reimagine learning has officially begun,<br/> and we’re
        excited to support you every step of the way.
      </p>
      <div className="big-img">
        <img src={vector} alt="vector" />
        <img src={banner} alt="banner" className="banner-img"/>
      </div>
      <h4>What’s Next?</h4>
      <p>Invite Your Team – Start by adding members to collaborate and make an
      impact together.</p>
      <p>Explore Features – Discover tools designed to empower
      both educators and learners.</p>
      <p>Reach Out Anytime – We’re here to help! If
        you have any questions or need assistance, don’t hesitate to contact our
        support team.</p>

      <button>Learn More</button>
     </div>
    </div>
    <LetterFooter/>
    </div>
    </div>
  );
}

export default OrgCreate;
