import React from "react";
import LetterHeader from "../components/LetterHeader";
import group from "../images/Logos/Group 1261156484.svg";
import LetterFooter from "../components/LetterFooter";

function Welcome() {
  return (
    <div className="welcome-letter-page">
      <div className="letter-page">
      <LetterHeader />
      <div className="container">
        <div className="letter-para">
          <div className="big-img">
            <img src={group} alt="group" />
          </div>
          <h2 className="letter-welcome-header">It all starts with you</h2>
          <p>
            Hello [User’s Name], <br />
            <br />
            Welcome to Ilmiya! We’re thrilled to have you join our community of
            educators, students, and lifelong learners. At Ilmiya, we’re
            dedicated to reimagining education by creating an innovative
            platform designed to empower learners and support educators every
            step of the way.
            <br />
            <br />
            Here’s what you can expect from Ilmiya:
            <br />
            <br />
            {/*    Thank you for choosing Ilmiya. Together, let’s create a
            future where learning knows no limits. Warm regards,The Ilmiya Team */}
          </p>
          <ul>
            <li>
              Personalized Learning Experiences: Our platform is designed to
              adapt to each student’s unique learning needs, making education
              both engaging and effective.
            </li>
            <li>
              Seamless Educator Support: From classroom management to student
              engagement tools, Ilmiya is here to help educators bring their
              vision to life.
            </li>
            <li>
              Scalable Solutions: Whether you're part of a small class or a
              large institution, Ilmiya is built to grow with you.
            </li>
          </ul>
          <p> Get Started Today!</p>
          <ol>
            <li> Explore the Platform –
            Log in to your account and navigate through the intuitive features
            that Ilmiya offers.</li>
            <li>Visit Our Help Center – Access guides, FAQs, and
            resources to make the most of your Ilmiya experience.</li>
            <li>
            Connect with
            Our Support Team – If you have any questions, our support team is
            here to help. Just reply to this email, and we’ll assist you as soon
            as possible.
            </li>
          </ol>
          <p>Thank you for choosing Ilmiya. Together, let’s create a
          future where learning knows no limits.<br/><br/> Warm regards,<br/>The Ilmiya Team </p>
        </div>
      </div>
      <LetterFooter />
    </div>
    </div>
  );
}

export default Welcome;
