export const space = window.location.hostname.split(".")[0];
console.log("🚀 ~ space:", space);
export const organization = () => JSON.parse(localStorage.getItem("space"));
console.log("🚀 ~ organization:", organization());
const localhost = process.env.REACT_APP_NODE_ENV === "localhost";
const development = process.env.REACT_APP_NODE_ENV === "development";
const staging = process.env.REACT_APP_NODE_ENV === "staging";

export const lxp_domains = ["myilmiya.dev", "myilmiya.app", "myilmiya.com"];
export const isValidDomain = (hostname) => {
  // Create a regular expression pattern to match the allowed domains
  const domainPattern = new RegExp(
    `(?:[a-zA-Z0-9-]+\\.)?(${lxp_domains.join("|")})$`
  );

  // Test the hostname against the pattern
  return domainPattern.test(hostname);
};
const moveToLogin = (url) =>
  isValidDomain(window.location.host)
    ? url
    : window.location.protocol + "//" + window.location.host + "";

export const redirect_uris = {
  Login: localhost
    ? `http://localhost:3000/`
    : development
      ? `https://accounts.ilmiya.dev/`
      : staging
        ? `https://accounts.ilmiya.app/`
        : `https://accounts.ilmiya.com/`,
  Accounts: localhost
    ? moveToLogin(`http://${space}.us.localhost:3001/`)
    : development
      ? moveToLogin(`https://${space}.myilmiya.dev/`)
      : staging
        ? moveToLogin(`https://${space}.myilmiya.app/`)
        : moveToLogin(`https://${space}.myilmiya.com/`),
  Learn: localhost
    ? `localhost:3002`
    : development
      ? `myilmiya.dev`
      : staging
        ? `myilmiya.app`
        : `myilmiya.com`,
  Studio: localhost
    ? `http://localhost:3004/engage/${space}/`
    : development
      ? `https://platform.ilmiya.dev/engage/${space}/`
      : staging
        ? `https://platform.ilmiya.app/engage/${space}/`
        : `https://platform.ilmiya.com/engage/${space}/`,
  People: localhost
    ? `http://localhost:3008/people/${space}/`
    : development
      ? `https://platform.ilmiya.dev/people/${space}/`
      : staging
        ? `https://platform.ilmiya.app/people/${space}/`
        : `https://platform.ilmiya.com/people/${space}/`,
  Teacher: localhost
    ? `http://localhost:3013/teacher/${space}/`
    : development
      ? `https://platform.ilmiya.dev/teacher/${space}/`
      : staging
        ? `https://platform.ilmiya.app/teacher/${space}/`
        : `https://platform.ilmiya.com/teacher/${space}/`,
  Analytics: localhost
    ? `http://localhost:3014/analytics/${space}/`
    : development
      ? `https://platform.ilmiya.dev/analytics/${space}/`
      : staging
        ? `https://platform.ilmiya.app/analytics/${space}/`
        : `https://platform.ilmiya.com/analytics/${space}/`,
  Console: localhost
    ? `http://localhost:3015/console/${space}/`
    : development
      ? `https://platform.ilmiya.dev/console/${space}/`
      : staging
        ? `https://platform.ilmiya.app/console/${space}/`
        : `https://platform.ilmiya.com/console/${space}/`,
};
