import React from "react";
import logo from "../images/ilmiya-site-icon.svg";

function WelcomeHeader() {
  return (
    <div className="welcome-div">
      <div className="container-fluid">
        <div className="welcome-header">
          <a href="#">
            <img src={logo} alt="logo" />
            <span>ILMIYA</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default WelcomeHeader;
