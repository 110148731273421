import React from "react";
import WelcomeHeader from "../components/WelcomeHeader";
import object from "../images/Email/OBJECTS.svg";
import WelcomeFooter from "../components/WelcomeFooter";

function SignupWelcome() {
  return (
  //  <div className="container-fluid">
     <div className="signup-welcome-page">
      <WelcomeHeader />
      <div className="container-fluid">
        <div className="signup-para">
          <img src={object} alt="object" className="object-img" />
          <h3>Welcome to the Ilmiya!</h3>
          <div className="d-flex justify-content-center">
            <p className="signup-margin">
              <span>Hi Bahaji,</span>
              <br />
              <br />
              Welcome to Ilmiya! We're thrilled to have you on board.<br/> If you
              have any questions or need assistance, feel free to reach out.<br/><br/><br/>
              Sincerely, <br/>The Ilmiya Team
            </p>
          </div>
        </div>
      </div>
      <WelcomeFooter/>
    </div>
  //  </div>
  );
}

export default SignupWelcome;
