import React from "react";
import LetterHeader from "../components/LetterHeader";
import vector from "../images/Logos/Vector.svg";
import LetterFooter from "../components/LetterFooter";
import banner from "../images/Logos/banner.svg";

function InvitedPage() {
  return (
    <div className="letter-page">
      <LetterHeader />
      <div className="container">
        <div className="letter-para">
        <div className="big-img">
        <img src={vector} alt="vector" />
        <img src={banner} alt="banner" className="banner-img"/>
      </div>
          {/* <img src={vector} alt="vector" /> */}
          <div className="letter-top">
          <h2 className="top">
            Adnan Tahir invite you <br/>to join the organization{" "}
            <br/>
            <span>Iqra Kidz</span>
          </h2>
          </div>

          <button className="invite-btn">
          Accept invite
          </button>
        </div>
      </div>
      <LetterFooter/>
    </div>
  );
}

export default InvitedPage;
