import React from "react";
import LetterHeader from "../components/LetterHeader";
import LetterFooter from "../components/LetterFooter";

function LetterPage() {
  return (
    <div className="letter-page">
      <LetterHeader />
      <div className="container">
        <div className="letter-para">
          <p>
              Dear [User's Name], <br /> <br />I want to personally welcome you
              to Ilmiya. Our mission is simple yet powerful: to reshape the way
              education is delivered, making it accessible, engaging, and
              effective for everyone. Thank you for joining us on this journey.{" "}
              <br /> <br /> At Ilmiya, we believe that learning is a lifelong
              process, and everyone deserves the tools and support to unlock
              their full potential. Our platform is built with both students and
              educators in mind, empowering them to create, explore, and grow in
              meaningful ways. We understand the challenges that come with
              education today—especially in a world that’s rapidly changing—and
              we’re committed to being a part of the solution. <br /> <br />{" "}
              This is just the beginning, and I’m excited for you to discover
              all that Ilmiya has to offer. From our adaptable learning tools to
              our educator support systems, each feature has been thoughtfully
              crafted to make a real impact. We’re here to support you every
              step of the way, whether you’re a student looking to excel or an
              educator striving to inspire. <br /> <br /> Please know that we’re
              more than just a platform; we’re a community driven by a shared
              vision of educational transformation. I invite you to explore,
              engage, and let us know how we can continue to improve and support
              your needs. <br /> <br /> Thank you for trusting us to be part of
              your learning journey. Together, let’s reimagine what’s possible
              in education. <br /> <br /> Warm regards, <br /> Taha Bahaji{" "}
              <br /> CEO,Ilmiya
            </p>
          
          
         
            
        </div>
      </div>
      <LetterFooter />
    </div>
  );
}

export default LetterPage;
