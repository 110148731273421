import React from "react";
import WelcomeHeader from "../components/WelcomeHeader";
import group from "../images/Email/Group 1000003161.svg";
import WelcomeFooter from "../components/WelcomeFooter";

function VerifyPage() {
  return (
      <div className="signup-welcome-page">
        <WelcomeHeader />
        <div className="container-fluid">
          <div className="signup-para">
            <img src={group} alt="group" className="verify-img" />
            <h3>Verify your email address</h3>
            {/* <div className="text-div"> */}
            <div className="text-div">
              <p className="verify-margin">
                <span>Hi Bahaji,</span>
              </p>
              <p>
                Welcome to Ilmiya! We're thrilled to have you on board.
                <br /> If you have any questions or need assistance, feel free
                to reach out.
              </p>
              <button className="span-btn verify-btn">Verify Your Email</button>
              <p>
                if you have any question, feel free to reach out
                <br />
                <br />
                Sincerely, <br />
                The Ilmiya Team
              </p>
            </div>
          </div>
        </div>
        <WelcomeFooter />
      </div>
   
  );
}

export default VerifyPage;
