import React from 'react'
import logo from "../images/ilmiya-site-icon.svg"

function LetterHeader() {
  return (
   <div className="container">
     <div className='letter-header'>
        <a href="#"><img src={logo} alt="logo"  className='ilmiya-logo'/></a>
    </div>
   </div>
  )
}

export default LetterHeader
