import React from "react";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { RiTwitterXFill } from "react-icons/ri";
import { IoLogoGithub } from "react-icons/io";

function WelcomeFooter() {
  return (
    <div className="letter-footer">
      <p>
        3800 American Blvd West Suite 1500 Bloomington, MN 55425 You are
        <br />
        <span>
          <a href="#">Unsubscribe</a>
        </span>{" "}
        - Unsubscribe Preferences
      </p>
      <div className="footer-links">
        <ul>
          <li>
            <a href="#">Privacy</a>
          </li>
          <li>
            <a href="#">Terms</a>
          </li>
          <li>
            <a href="#">Support</a>{" "}
          </li>
        </ul>
      </div>
      <div className="footer-social-icon">
      <a href="#"> <FaFacebook /></a>
        <a href="#"><RiTwitterXFill /></a>
        <a href="#"><AiOutlineInstagram /></a>
        <a href="#"><FaLinkedin /></a>
        <a href="#"><IoLogoGithub /></a>
      </div>
    </div>
  );
}

export default WelcomeFooter;
