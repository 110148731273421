import React from 'react'
import WelcomeHeader from "../components/WelcomeHeader";
import object from "../images/Email/OBJECTS.svg";
import WelcomeFooter from "../components/WelcomeFooter";

function OrganizationPage() {
  return (
    <div className="signup-welcome-page">
      <WelcomeHeader />
      <div className="container-fluid">
        <div className="signup-para">
          <img src={object} alt="object" className="object-img" />
          <h3>Organiztion Created for<br/>
          {/* <a href="#">alpha@ilmia.com</a> */}
          </h3>
          <span><a href="#">alpha@ilmia.com</a></span>
          <div className="text-div">
            <p className='organization-margin'><span>Hi Bahaji,</span></p>
            <p>
              Welcome to Ilmiya! We're thrilled to have you on board.<br/> If you
              have any questions or need assistance, feel free to reach out.<br/><br/><br/>
              Sincerely, <br/>The Ilmiya Team
            </p>
          </div>
        </div>
      </div>
      <WelcomeFooter/>
    </div>
  )
}

export default OrganizationPage
