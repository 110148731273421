import React from "react";
import LetterHeader from "../components/LetterHeader";
import frame from "../images/Logos/Frame.svg";
import LetterFooter from "../components/LetterFooter";

function ThanksPage() {
  return (
    <div className="thank-page">
      <div className="letter-page">
      <LetterHeader />
      <div className="container">
        <div className="letter-para">
          <div className="letter-small-img">
            <img src={frame} alt="frame" />
            <span>Ilmiya Support</span>
          </div>
          <h2 className="thanks-header">We’re here to help!</h2>
          <p>
          Thank you for reaching out to Ilmiya Support. We’ve received your
            request and are currently reviewing it. To add any additional
            comments, simply reply to this email.
          </p>
          <p> We appreciate your patience as
            our team works to address your issue. Due to a higher-than-usual
            volume of requests, response times may be slightly delayed. However,
            please rest assured that we are committed to giving each request the
            attention it deserves. While we aim to get back to you as quickly as
            possible, it may take up to one business day for a response.</p>
            <p>
            We
            understand that timely support is important, and we’re prioritizing
            all time-sensitive issues. An update will be provided as soon as we
            have more information. In the meantime, you can explore our Help
            Center for additional resources. If you need assistance with any
            specific account concerns, feel free to let us know.
            </p>
            <p>
            Thank you,<br/>
            The Ilmiya Support Team
            </p>
        </div>
      </div>
      <LetterFooter/>
    </div>
    </div>
  );
}

export default ThanksPage;
