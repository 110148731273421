import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import logo from "../images/icons/logo.svg";
import errorIcon from "../images/icons/error-icon.svg";
import errorBlack from "../images/icons/error-black.svg";
import { redirect_uris } from "../../constants";
import { Spinner } from "react-bootstrap";

export default function RouterPage() {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      value: "",
    },
    validationSchema: Yup.object({
      value: Yup.string()
        .required("Organization Name is required")
    }),
    onSubmit: (values) => {
      setLoading(true);
      const value = values.value;
      axios
        .get(`${process.env.REACT_APP_API_BASE_PATH}organizations/${value}/name`)
        .then((resp) => {
          if (resp.status === 200) {
            setErrorMessage("");
            window.open(`http://${value}.${redirect_uris.Learn}`, "_blank");
          }
        })
        .catch((errors) => {
          if (errors.response && errors.response.status === 404) {
            setErrorMessage(
              "We couldn’t find your Organization. If you can’t remember your Org URL, check your invite email or contact your admin."
            );
          } else {
            setErrorMessage("An error occurred while fetching data.");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleButtonClick = () => {
    if (!formik.isValid) {
      formik.setTouched({ value: true }); // Force validation error to show
    } else {
      formik.handleSubmit(); // Trigger form submission
    }
  }

  return (
    <>
      <SecondaryHeader />
      <div className="router-page-wrapper">
        <div className="container">
          <div className="router-page-form">
            <form className="form-wrapper">
              <div className="form-logo">
                <img src={logo} alt="icon" />
              </div>
              <div className="form-title">
                <h1>Find Organization</h1>
              </div>
              <div className="form-input-section">
                <div className={`input-section ${formik.errors.value && formik.touched.value ? "error" : ""}`}>
                  <input
                    type="text"
                    placeholder="tenant"
                    name="value"
                    value={formik.values.value}
                    onChange={(e) => {
                      formik.handleChange(e); // Handle formik value update
                      setErrorMessage(""); // Clear the error message
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <div className="domain">
                    <span>.myilmiya.{process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "localhost" ? "dev" : process.env.REACT_APP_NODE_ENV === "staging" ? "app" : "com"}</span>
                  </div>
                </div>
                {formik.errors.value && formik.touched.value && (
                  <div className="error-message">{formik.errors.value}</div>
                )}
                <div className="submit-btn">
                  <button type="button" onClick={handleButtonClick}>
                    {loading ? <Spinner animation="border" size="sm" /> : "Next"}
                  </button>
                </div>
              </div>
            </form>
            {errorMessage && (
              <div className="error-section">
                <div className="icon">
                  <img src={errorIcon} alt="icon" className="light-theme-img" />
                  <img src={errorBlack} alt="icon" className="dark-theme-img" />
                </div>
                <p>{errorMessage}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <SecondaryFooter />
    </>
  );
}
