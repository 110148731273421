import React from 'react'
import WelcomeHeader from '../components/WelcomeHeader';
import img from "../images/Email/Ellipse 2.svg";
import WelcomeFooter from '../components/WelcomeFooter';

function MemberPage() {
  return (
    // <div className="container-fluid">
      <div className='signup-welcome-page'>
        <WelcomeHeader/>
        <div className="container-fluid">
            <div className="signup-para">
                <div className='img-border'>
                <img src={img} alt="img" className='person-img' />
                </div>
                <h3>Bahaji invite to you!</h3>
                  <button className='span-btn member-margin'>Accept invitation</button>
                  <p className='valid-date'> Valid till: Dec 25, 2025</p>
    

            </div>
        </div>
        <WelcomeFooter/>
      
    </div>
    // </div>
  )
}

export default MemberPage
